import React from 'react'
// import HeroMarketing from './HeroMarketing'
// import Metrics from './Metrics'
// import NewsLetter from './NewsLetter'
// import Services from './Services'
// import ServicesSide from './ServicesSide'
// import Stats from './Stats'
// import CTA from './HomePage/CTA'
// import ScrollImage from './ScrollImage'
// import Footer from './Footer'

export default function Marketingdna() {
    return (
        <>
            {/* <HeroMarketing />
            <NewsLetter />
            <Services />
            <ServicesSide />
            <Stats />
            <Metrics />
            <CTA />
            <ScrollImage /> */}
        </>
    )
}
